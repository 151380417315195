'use client';

import React from 'react';
import type { ErrorPage as ErrorPageType } from '@/types';
import ErrorPage from '@/pagewrappers/ErrorPage';
import errorPageData from '@/pages/api/cms/500.json';
import useTrackException, { ErrorType } from '@/global/hooks/useTrackException';

type ErrorProps = {
  error: ErrorType;
};

const GlobalError = (props: ErrorProps) => {
  const { error } = props;
  const pageData = errorPageData as unknown as ErrorPageType;
  useTrackException(error, undefined, { enabled: false });

  return <ErrorPage data={pageData} />;
};

export default GlobalError;
